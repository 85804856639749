.home-bg-container {
    padding: 20px;
}

.each-carousel {
    margin-top: 0px;
}

.carousel-containerr {
    position: relative;
    margin-top: 5px;
}

.home-image {
    width: 100%;
    height: 77.5vh;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.homepage-bottom-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-containers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.content-container {
    /* width: 100%; */
    width: 50%;
    /* background-color: #83214F;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px; */
}

.card-image {
    height: 140px;
    width: 100px;
    border-radius: 20px;
}

.card-heading {
    font-size: 20px;
    font-weight: bolder;
    color: #FAF1F8;
}

.card-content {
    font-size: 16px;
    color: #FAF1F8;
}

.card-link {
    text-decoration: none;
    color: inherit;
    width: 40%;
}

.content-sub-container {
    display: flex;
    width: 95%;
    background-color: #83214F;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.content-main-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


@media (max-width: 768px) {
    .home-image {
        height: 50vh;
        /* Reduced height on mobile devices */
        object-fit: cover;
    }
}

@media (max-width: 480px) {
    .home-image {
        height: 40vh;
        /* Further reduced height for smaller screens */
    }
}