.privacy-container {
     margin: auto;
    max-width: 1000px;
    padding: 20px;
}

h1 {
    color: #83214F;
    text-align: center;
    margin-bottom: 5px;
}

h2,
h3 {
    color: black;
    margin-top: 10px;
    font-size: 18px;
}

p {
    color:black;
}

.updated-text {
    font-weight: bold;
    color: black;
    text-align: right;
}

ul {
    margin-left: 20px;
    list-style-type: disc;
}

ul li {
    margin-bottom: 5px;
}

.privacy-heading {
    font-size: 25px;
}

@media (max-width: 768px) {
    .privacy-container {
        padding: 15px;
    }

    .privacy-heading {
        font-size: 20px;
    }
}