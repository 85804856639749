/* Default styles (for larger screens) */
.login-bg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #ffffff;
    flex-direction: column;
    padding: 1rem;
}

/* Login Form */
.login-form-container {
    background-color: #FAF1F8;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 350px;
}

/* Headings */
h2 {
    margin-bottom: 1.5rem;
    color: #83214F;
}

/* Input Fields */
.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

/* Submit Button */
.submit-button {
    background-color: #83214F;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #6b1c42;
}

/* OTP Inputs */
.otp-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 1rem;
}

.otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Logo */
.logo-image {
    height: 200px;
    width: 200px;
    margin-bottom: 30px;
}

/* Mobile Number Heading */
.mobile-heading {
    text-align: left;
    color: #000000;
    font-size: 18px;
}

/* === Responsive Design for Mobile Screens === */
@media (max-width: 600px) {
    .login-bg-container {
        height: auto;  /* Let height adjust dynamically */
        padding: 2rem 1rem;
    }

    .login-form-container {
        width: 90%; /* Make form take more space on small screens */
        padding: 1.5rem;
    }

    .otp-container {
        gap: 5px; /* Reduce space between OTP boxes */
    }

    .otp-input {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    .logo-image {
        height: 150px;
        width: 150px;
    }

    .mobile-heading {
        font-size: 16px;
    }

    h2 {
        font-size: 20px;
    }

    .submit-button {
        font-size: 14px;
        padding: 8px;
    }
}
