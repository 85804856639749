/* .news-details-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.news-details-container h2 {
    margin-bottom: 10px;
}

.news-details-container p {
    margin: 10px 0;
}

.news-images {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.news-images img {
    width: 100px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
} */

.event-details-container {
    width: 100%;
}

.event-details-container h2 {
    margin-bottom: 16px;
    font-size: 22px;
}

.event-details-container p {
    margin: 12px 0;
    font-size: 1rem;
    line-height: 1.5;
}

.image-container {
    margin-bottom: 20px;
    padding-top: 40px;
}

.event-image {
    width: 50%;
    height: 600px;
}

.content-container {
    padding-left: 40px;
}